import classnames from 'classnames'
import * as React from 'react'

interface TopbarProps {
  className?: string
  navContent?: React.ReactNode
  mapControls?: React.ReactNode
}

export function Topbar(props: React.PropsWithChildren<TopbarProps>) {
  return (
    <div className={classnames(props.className, 'mb-0.5 flex h-10 items-center')}>
      {props.navContent && (
        <div className="map-view-switch flex w-[201px] items-center self-stretch border-r border-inherit px-4">
          {props.navContent}
        </div>
      )}
      <div className="flex grow items-center pl-5">{props.children}</div>
      {props.mapControls}
    </div>
  )
}
